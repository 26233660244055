import React, { useEffect, useState, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import styles from "../../servicesSlider/services.module.css";
import { Pagination, Autoplay } from "swiper/modules";
import { useMediaQuery } from "react-responsive";
import { useI18nContext } from "../../context/I18nContext";
import axios from "axios";
import NavScrollExample from "../../header/Header";
import Footer from "../../footer/Footer";
import { useParams } from "react-router-dom";
import Loading from "../../Loading";
import { Helmet } from "react-helmet-async";

const ProjectPage = () => {
  const { id } = useParams();
  const [project, setProject] = useState({
    images: [],
    name_ar: "",
    name_en: "",
    category: { name_en: "", name_ar: "" },
    description_en: "",
    description_ar: "",
    cover: "",
  });
  const { language } = useI18nContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [slides, setSlides] = useState([]);
  const isLgScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  const isTabScreen = useMediaQuery({ query: "(min-width: 765px)" });

  const fetchProject = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://dramsiteapi.onrender.com/api/v1/projects/${id}`,
        {
          withCredentials: true,
          headers: { "X-API-KEY": "k2017secret" }
        }
      );
      const result = response.data;

      if (result && result.data) {
        setProject(result.data);
        setLoading(false);
        const slidesArray = result.data.images;
        if (Array.isArray(slidesArray)) {
          setSlides(
            slidesArray.map((image) => ({
              name:
                language === "ar" ? result.data.name_ar : result.data.name_en,
              cover: image,
            }))
          );
        } else {
          // console.error("Unexpected data format:", result);
          setLoading(false);
          setError("Failed to load check your network");
        }
      } else {
        // throw new Error("Invalid response structure");
        setLoading(false);
        setError("Failed to load check your network");
      }
    } catch (error) {
      // console.error("Error fetching project:", error);
      setLoading(false);
      setError("Failed to load check your network");
    }
  }, [id, language]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  return (
    <>
      <Helmet>
        <title>{`DRAM Code - ${project.name_en || "Project"}`}</title>
        <link rel="canonical" href={`https://dramsiteapi.onrender.com/projects/${id}`} />
        <meta
          name="description"
          content={`Discover the details of the ${project.name_en || "project"
            }, including its features and how it can benefit your business. ${project.description_en || ""
            }`}
        />
      </Helmet>
      <div>
        <div className={`bg-[#011b3d]  `}>
          <NavScrollExample />
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <Loading />
          </div>
        ) : error ? (
          <div
            class="alert alert-danger d-flex justify-center align-items-center my-4 w-[80%] m-auto"
            role="alert"
          >
            {error}
          </div>
        ) : (
          <div dir={language === "ar" ? "rtl" : "ltr"} className="m-5">
            <div>
              <h1>
                <span className="text-blue-500 font-bold">
                  {language === "ar" ? project.name_ar : project.name_en}
                </span>
                {" - "}{" "}
                {language === "ar"
                  ? project.category.name_ar
                  : project.category.name_en}
              </h1>
              <p className=" text-xl">
                {language === "ar"
                  ? project.description_ar
                  : project.description_en}
              </p>
              <div className="w-1/2 h-1/2  my-5 mx-auto ">
                <img
                  // data-aos="zoom-in"
                  className={`rounded-3 `}
                  src={project.cover}
                  alt={project.name}
                />
              </div>
              <div className={`${styles.slider} ${styles.slider2}`}>
                <Swiper
                  slidesPerView={isLgScreen ? 3 : isTabScreen ? 2 : 1}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  modules={[Pagination, Autoplay]}
                  className={styles.swiper}
                >
                  {slides.map((slide, index) => (
                    <SwiperSlide className={styles.swiperSlide} key={index}>
                      <div className={styles.card}>
                        <div className={styles.imageContainer}>
                          <img
                            className={`rounded-3 ${styles.image}`}
                            src={slide.cover}
                            alt={slide.name}
                            layout="fill"
                            loading="lazy"
                          />
                          <div className={styles.overlay}>
                            <p className={styles.text}>{slide.name}</p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default ProjectPage;
