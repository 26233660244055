import React from "react";
import AboutService from "./aboutService/page";
import NavScrollExample from "../header/Header";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet-async";

const Services = () => {
  return (
    <>
      <Helmet>
        <title>DRAM Code - Services</title>
        <link rel="canonical" href="https://dramsiteapi.onrender.com/services" />
        <meta
          name="description"
          content="Discover our comprehensive range of software development services designed to meet the unique needs of your business."
        />
      </Helmet>
      <div>
        <div className={`bg-[#011b3d] `}>
          <NavScrollExample />
        </div>
        <AboutService />
        <Footer />
      </div>
    </>
  );
};

export default React.memo(Services);
