import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Image } from "react-bootstrap";
import { useI18nContext } from "../../context/I18nContext";
import styles from "./style.module.css";
import logoImage from "../../header/logo side transparent.svg";
import Loading from "../../Loading";
import NavScrollExample from "../../header/Header";
import Footer from "../../footer/Footer";
import { Helmet } from "react-helmet-async";

const ServicesComponent = () => {
  const { id } = useParams();
  const [service, setService] = useState(null);
  const { language } = useI18nContext();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await axios.get(
          `https://dramsiteapi.onrender.com/api/v1/services/${id}`,
          {
            withCredentials: true,
            headers: { "X-API-KEY": "k2017secret" }
          }
        );
        setLoading(false);
        setService(response.data.data);
      } catch (error) {
        setLoading(false);
        // console.error("Error fetching service data:", error);
        setError("Failed to load check your network");
      }
    };

    if (id) {
      fetchServiceData();
    }
  }, [id]);

  return (
    <>
      <Helmet>
        <title>{`DRAM Code - ${service?.name_en || "Service"}`}</title>
        <link rel="canonical" href={`https://dramsiteapi.onrender.com/services/${id}`} />
        <meta
          name="description"
          content={`Discover the details of the service: ${service?.name_en || "service"
            }. ${service?.description_en || ""}`}
        />
      </Helmet>

      <div>
        <div className="bg-[#011b3d] ">
          <NavScrollExample />
        </div>
        {service ? (
          <div
            dir={language === "ar" ? "rtl" : "ltr"}
            className={`flex align-center justify-between max-md:justify-center ${styles.mm}`}
          >
            <div
              key={service._id}
              className={`h-75 mt-3 ${styles.mbb} max-tab:w-96`}
            >
              <h1
                className={`text-blue-500 text-3xl font-bold relative ${styles.mbb} ${styles.line2}`}
              >
                {language === "ar" ? service.name_ar : service.name_en}
                {/* <div></div> */}
              </h1>
              <h3 className="text-justify text-lg">
                {language === "ar" ? service.intro_ar : service.intro_en}
              </h3>
              <h5 className="text-justify text-lg">
                {language === "ar"
                  ? service.description_ar
                  : service.description_en}
              </h5>
            </div>
            <div>
              <div className={`${styles.wave} z-0`}>
                <Image
                  src={logoImage}
                  alt="logo Image"
                  className={styles.Image2}
                  width={1200}
                  height={1200}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        ) : error ? (
          <div
            class="alert alert-danger d-flex justify-center align-items-center my-4 w-[80%] m-auto"
            role="alert"
          >
            {error}
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <Loading />
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default ServicesComponent;
